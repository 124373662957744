// Styling for index.html, primarily for pre-app loading or any global defaults
// Should NOT be imported into primary stylesheets, but specified directly in project.json assets
// to allow for bundle splitting to load this file as quickly as possible

@use 'primeflex/core/variables' as primeflex;
@use './theme/variables' as variables;

// Base global rem setting
html {
  $fontSize: 14px;
  // Fallback
  font-size: $fontSize;

  // < md
  @media only screen and (max-width: primeflex.$md) {
    font-size: $fontSize - 2px;
  }

  // md <-> lg
  @media only screen and (min-width: primeflex.$md) and (max-width: primeflex.$lg) {
    font-size: $fontSize - 1px;
  }

  // lg <-> xl
  @media only screen and (min-width: primeflex.$lg) and (max-width: primeflex.$xl) {
    font-size: $fontSize;
  }

  // xl+
  @media only screen and (min-width: primeflex.$xl) {
    font-size: $fontSize + 2px;
  }
}
/*
// Not quite working, leaving as example of vw-based font sizing
html {
  $minFontSize: 12px;
  $maxFontSize: 24px;
  $minScreenSize: primeflex.$sm;
  $maxScreenSize: primeflex.$xl;

  font-size: calc(
    $minFontSize + ($maxFontSize - $minFontSize) * (100vw - $minScreenSize) /
      ($maxScreenSize - $minScreenSize)
  );
}
*/

body {
  background-color: var(--surface-ground);

  #splash-screen {
    /*transition-property: opacity, display;
    transition-duration: 0.15s;
    transition-timing-function: ease-in;*/

    display: flex;
    flex-direction: column;
    height: 100dvh;
    align-items: center;
    justify-content: space-evenly;
    background-color: variables.$brandSecondaryColor;

    img {
      margin-bottom: 15rem;
    }
  }

  // Hide splash screen once app is not empty
  &:has(rc-app:not(:empty)) {
    #splash-screen {
      display: none !important;
    }
  }
}
